import Discord      from "./images/icons/Discord.png";
import Twitter      from "./images/icons/Twitter.png";
import NexusMods    from "./images/icons/NexusMods.png";
import YouTube      from "./images/icons/YouTube.png";

export default {
    Discord,
    Twitter,
    NexusMods,
    YouTube
}